import React from 'react';

function Repositories() {
    return (
        <div className="App">
            <div className="App-header">
                <h2>Repositories</h2>
            </div>
        </div>
    );
}

export default Repositories;