import React from "react";

function Footer() {
    return (
        <div className="App-footer">
            <footer>
                Copyright &copy; 2023 CoderDP, All Rights Reserved
            </footer>
        </div>
    );
}

export default Footer;